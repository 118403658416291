import React from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

// Bootstrap & Layout
import Col from 'react-bootstrap/Col'
import Layout from '../components/Layout/layout'
import Section from '../components/Section/section'

//Components
import SEO from '../components/SEO/seo'

//Utils
import {STRIPE_CHECKOUT_SLUG, STRIPE_CUSTOMER_SLUG, STRIPE_ENDPOINT, STRIPE_READ_KEY, SLACK_ENDPOINT, SLACK_DIRECT_ACCOUNT_PAYLOAD, SEGMENT_IDENTIFY, SEGMENT_EVENT} from '../utils/constants'
import {PRICING_DATA} from '../utils/pages/qsParentsPageData'

const SUCCESS_TICK = (measurements=48) => <svg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px'
    width={measurements} height={measurements}
    viewBox='0 0 48 48'
    style=' fill:#000000;'><linearGradient id='I9GV0SozQFknxHSR6DCx5a_70yRC8npwT3d_gr1' x1='9.858' x2='38.142' y1='9.858' y2='38.142' gradientUnits='userSpaceOnUse'><stop offset='0' stopColor='#21ad64'></stop><stop offset='1' stopColor='#088242'></stop></linearGradient><path fill='url(#I9GV0SozQFknxHSR6DCx5a_70yRC8npwT3d_gr1)' d='M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z'></path><path d='M32.172,16.172L22,26.344l-5.172-5.172c-0.781-0.781-2.047-0.781-2.828,0l-1.414,1.414	c-0.781,0.781-0.781,2.047,0,2.828l8,8c0.781,0.781,2.047,0.781,2.828,0l13-13c0.781-0.781,0.781-2.047,0-2.828L35,16.172	C34.219,15.391,32.953,15.391,32.172,16.172z' opacity='.05'></path><path d='M20.939,33.061l-8-8c-0.586-0.586-0.586-1.536,0-2.121l1.414-1.414c0.586-0.586,1.536-0.586,2.121,0	L22,27.051l10.525-10.525c0.586-0.586,1.536-0.586,2.121,0l1.414,1.414c0.586,0.586,0.586,1.536,0,2.121l-13,13	C22.475,33.646,21.525,33.646,20.939,33.061z' opacity='.07'></path><path fill='#fff' d='M21.293,32.707l-8-8c-0.391-0.391-0.391-1.024,0-1.414l1.414-1.414c0.391-0.391,1.024-0.391,1.414,0	L22,27.758l10.879-10.879c0.391-0.391,1.024-0.391,1.414,0l1.414,1.414c0.391,0.391,0.391,1.024,0,1.414l-13,13	C22.317,33.098,21.683,33.098,21.293,32.707z'></path>
</svg>

const TIMELINE = [
    '',
    'Less than a month away',
    '3 months away',
    '3 - 6 months away',
    '6 - 9 months away',
    '9+ months away',
    'Not sure'
]

const EXAM = [
    '',
    'IGCSE',
    'GCSE',
    'O Level'
]

const SUCCESS_MESSAGE = 'We\'ll create your account within 24 hours. Contact us at hello@quicksense.org, if you have any questions or do not hear from us.'

const ERROR_MESSAGE = 'We had trouble processing your request. Feel free to try again. If this keeps happening, email us at hello@quicksense.org'

const FORM_SCHEMA = Yup.object().shape({
    parentName: Yup.string()
        .required('Enter your name so we know who you are')
        .nullable(),

    parentEmail: Yup.string()
        .email('Check your email address. Did you add @?')
        .nullable()
        .required('We need this to inform you when your QuickSense account is ready'),
      
    childEmail: Yup.string()
        .email('Check your email address. Did you add @?')
        .required(`We need this so we can create an account for your child. If you want them to use your email address enter that instead.`),
  
    childName: Yup.string()
        .required('We need this identify who is enrolled in our premium cohort, and to provide access to our Discord premium channel.'),

    exam: Yup.string()
        .required('We need this so we know which stream to enrol them for our pre-exam sessions'),

    timeline: Yup.string()
        .required('This helps us plan for our pre-exam tutoring sessions')
})

const REQUIRED_ASTERIX = <span className='text-danger pr-1'>*</span>

class SuccessPage extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            loading: false,
            valid: false,
            error: false,
            checkout: null,
            customer: null,
            stripeDirect: null,
            plan: null,
            price: null,
            showNotification: false,
            notificationMessage: null,
            errorMessage: false
        }
    }

    componentDidMount(){
        let getURL = new URL(window.location.href)
        let getSearchParams = new URLSearchParams(getURL.search)
        let getStripeId = getSearchParams.getAll('id')
        let getStripeCustomer = getSearchParams.getAll('customer')

        if (getSearchParams.has('from') && this.__validateFromStripe(getSearchParams.getAll('from')) ){
            this.setState({loading: false, valid: true, error: false, stripeDirect: true})
        }

        if (getStripeId.length > 0 && getSearchParams.has('customer')) {
            this.__validateInfo(getStripeCustomer, STRIPE_CUSTOMER_SLUG, 'customer')
        }

        if (getStripeId.length > 0 && getSearchParams.has('id')) {
            this.__validateInfo(getStripeId, STRIPE_CHECKOUT_SLUG, 'checkout')
        }
    }

    __validateFromStripe(query){
        if (atob(query) === 'true:quicksense premium'){
            this.setState({plan: PRICING_DATA[0].product, price: PRICING_DATA[0].price})
            return true 
        }  
        else if (atob(query) === 'true:quicksense premium plus'){
            this.setState({plan: PRICING_DATA[1].product, price: PRICING_DATA[1].price})
            return true
        }  
        else return false        
    }

    __validateInfo(requestId, slug, state){
        this.setState({loading: true, valid: true})

        fetch(`${STRIPE_ENDPOINT}${slug}${requestId}`, {
            method: 'GET',
            headers: {'Authorization' : `Bearer ${STRIPE_READ_KEY}`}
        })
            .then(response => {
                if (!response.ok) this.setState({error:true})
                return response.body
            })
            .then(rb => {
                const reader = rb.getReader()
                return new ReadableStream({
                    start(controller) {
                        // The following function handles each data chunk
                        function push() {
                            // "done" is a Boolean and value a "Uint8Array"
                            reader.read().then( ({done, value}) => {
                                // If there is no more data to read
                                if (done) {
                                    controller.close()
                                    return
                                }
                                // Get the data and send it to the browser via the controller
                                controller.enqueue(value)
                                // Check chunks by logging to the console
                                push()
                            })
                        }
    
                        push()
                    }
                })
            })
            .then(stream => {
            // Respond with our stream
                return new Response(stream, { headers: { 'Content-Type': 'text/json' } }).json()
            })
            .then(result => {
                this.setState({ loading: false })
                state == 'customer' ? this.setState({ customer: result }) : this.setState({ checkout: result })
            })
            .catch(error => {
                console.error('Error:', error)
                this.setState({error: true})
            })
    }

    submitForm(values){
        /** SLACK WEBHOOKS: https://stackoverflow.com/questions/45752537/slack-incoming-webhook-request-header-field-content-type-is-not-allowed-by-acce **/
        /** Tldr: Make sure there's no header as it breaks via JS. Alternatives is using header: application/x-www-form-urlencoded **/
        /** Slack payload will break unless it is wrapped within another object **/

        SEGMENT_IDENTIFY(values)
        SEGMENT_EVENT(values, 'website_post_purchase_form_sent')

        fetch(SLACK_ENDPOINT, {
            method: 'POST',
            body: JSON.stringify(SLACK_DIRECT_ACCOUNT_PAYLOAD(values))
        }).then(response => {
            if (!response.ok) { 
                this.setState({showNotification: true, notificationMessage: ERROR_MESSAGE, errorMessage: true})
                setTimeout(() => this.setState({showNotification: false}), 9000)
            }
            else {
                this.setState({showNotification: true, notificationMessage: SUCCESS_MESSAGE})
                setTimeout(() => this.setState({showNotification: false}), 9000)
            }
        }).catch(console.error)
    }

    render(){
        const FORM_VALUES = {
            parentName: '',
            parentEmail: '',
            childName: '',
            childEmail:'',
            exam: '',
            timeline: '',
            purchaseId: '',
            customerId: '',
            plan: this.state.plan,
            price: this.state.price
        }
        return <>
            <SEO title={'Success'}/>
            <Layout>
                <Section center>
                    <Col lg={10} className='col-12 col-lg-10' as='section'>
                        {
                            this.state.loading && <div className='d-flex flex-column align-items-center'>
                                <div className='spinner-border' role='status'>
                                    <span className='sr-only'>Loading...</span>
                                </div>
                                <strong>Loading...</strong>
                            </div>
                        }
                        {
                            (!this.state.valid || this.state.error) && <div className='alert alert-danger' role='alert'>
                                <strong>Error: You do not have the necessary permissions to access this page. </strong>
                                <br/><br/>
                               Please contact us or try again. 
                            </div>
                        }
                        {
                            (!this.state.loading && !this.state.error && this.state.valid)  &&
                            <>
                                <h1 className='mt-5 mt-sm-0'>Hi {this.state.customer ? this.state.customer.name : 'there,'}</h1>

                                { this.state.stripeDirect && <p>{SUCCESS_TICK(24)}<strong>Your payment was successful!</strong></p>}
                                { this.state.stripeDirect && <p><small><em>If you want to fill this in later, {`we've`} emailed you a link to access this page</em></small></p>}
                                <hr/>
                                <h2>Next steps</h2>
                                <p> Fill in the form below. We will provide your child access to QuickSense and our discourd channel within 24 hours.</p>
                                <p><small><em>(Note: May take up to 48 hours over the weekend)</em></small></p>
                                <p><small><em>If you run into any issues, contact us directly at hello@quicksense.org</em></small></p>
                                <Formik initialValues={FORM_VALUES} validationSchema={FORM_SCHEMA} onSubmit={(values, { setSubmitting, resetForm }) => {
                                    setTimeout(() => {
                                        this.submitForm(values)
                                        setSubmitting(false)
                                        resetForm()
                                    }, 400)
                                }}
                                >
                                    {({ errors,touched, isSubmitting }) => (
                                        <Form className='col-12 bg-white shadow py-3 py-sm-4 px-sm-4 rounded'>
                                            <h2 className='h3'>Your details</h2>
                                            <div className='form-group'>
                                                <label htmlFor='parentName'>{REQUIRED_ASTERIX}Name</label>
                                                <Field className={`form-control ${ errors.parentName && touched.parentName ? 'is-invalid' : ''} ${!errors.parentName && touched.parentName ? 'is-valid' : ''}`}  type='text' name='parentName' disabled={isSubmitting}/>
                                                {errors.parentName && touched.parentName && (<small className='form-text text-danger'>{errors.parentName}</small>)}
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor='parentEmail'>{REQUIRED_ASTERIX}Email</label>
                                                <Field className={`form-control ${ errors.parentEmail && touched.parentEmail ? 'is-invalid' : ''} ${!errors.parentEmail && touched.parentEmail ? 'is-valid' : ''}`} type='email' name='parentEmail' disabled={isSubmitting}/>
                                                {errors.parentEmail && touched.parentEmail && (<small className='form-text text-danger'>{errors.parentEmail}</small>)}
                                            </div>
                                            <hr/>
                                            <h2 className='h3'>Your child</h2>
                                            <div className='form-group'>
                                                <label htmlFor='childName'>{REQUIRED_ASTERIX}{`Child's name`}</label>
                                                <Field className={`form-control ${ errors.childName && touched.childName ? 'is-invalid' : ''} ${!errors.childName && touched.childName ? 'is-valid' : ''}`}  type='text' name='childName' disabled={isSubmitting}/>
                                                {errors.childName && touched.childName && (<small className='form-text text-danger'>{errors.childName}</small>)}
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor='childEmail'>{REQUIRED_ASTERIX}{`Child's email`}</label>
                                                <Field className={`form-control ${ errors.childEmail && touched.childEmail ? 'is-invalid' : ''} ${!errors.childEmail && touched.childEmail ? 'is-valid' : ''}`}  type='text' name='childEmail' disabled={isSubmitting}/>
                                                {errors.childEmail && touched.childEmail && (<small className='form-text text-danger'>{errors.childEmail}</small>)}
                                                {!errors.childEmail && <small>This will be the email they use to log into the platform.</small>}
                                            </div>
                                            <hr />
                                            <h2 className='h3'>Exam Details</h2>
                                            <div className='form-group'>
                                                <label htmlFor='exam'>{REQUIRED_ASTERIX}{`What exam are they sitting?`}</label>
                                                <Field className={`form-control ${ errors.exam && touched.exam ? 'is-invalid' : ''} ${!errors.exam && touched.exam ? 'is-valid' : ''}`}  as='select' name='exam' disabled={isSubmitting}>
                                                    {
                                                        EXAM.map((label, index) => (
                                                            <option value={label} key={index} disabled={index == 0 && true} selected={index == 0 && true} hidden={index == 0 && true}>{label}</option>
                                                        ))
                                                    }
                                                </Field>
                                                {errors.exam && touched.exam && (<small className='form-text text-danger'>{errors.exam}</small>)}
                                            </div>
                                            <div className='form-group'>
                                                <label>{REQUIRED_ASTERIX}{`When is the exam?`} </label>
                                                <Field name='timeline' as='select' className={`form-control ${ errors.timeline && touched.timeline ? 'is-invalid' : ''}  ${!errors.timeline && touched.timeline ? 'is-valid' : ''}`}>
                                                    {
                                                        TIMELINE.map((label,index) => (
                                                            <option value={label} key={index} disabled={index == 0 && true} selected={index == 0 && true} hidden={index == 0 && true}>{label}</option>
                                                        ))
                                                    }
                                                </Field>
                                                {!errors.timeline && !touched.timeline && <em className='text-small'>This helps us plan for our pre-exam tutoring sessions</em>}
                                                {errors.timeline && touched.timeline && (<small className='form-text text-danger'>{errors.timeline}</small>)}
                                            </div>
                                            <button className='btn btn-primary mb-3 px-5' type='submit' disabled={isSubmitting}>
                            Submit
                                            </button>
                                            {
                                                this.state.showNotification &&
                              <div className={`'alert rounded p-3 ${this.state.errorMessage ? 'alert-danger' : 'alert-success'}`}>
                                  <strong className='d-flex align-items-center'>{this.state.errorMessage ? null : SUCCESS_TICK(24)}{this.state.errorMessage ? 'Error' : 'Success'}</strong>
                                  <p className='m-0'><small>{this.state.notificationMessage}</small></p> 
                              </div> 
                                            }
                                        </Form>
                                    )}   
                                </Formik>
                            </>
                        }
                    </Col>
                </Section>
            </Layout>
        </>
    }

}

export default SuccessPage